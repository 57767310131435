<template>
  <div>
    <div class="row justify-content-end">
      <div v-if="userRole == 'Admin'" class="col-auto">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'repairs-items-create' })"
        >
          <i class="bi bi-file-earmark-plus fs-4"></i>
          Ajouter d'autres articles
        </button>
      </div>
      <div v-else-if="userRole == 'Magasinier'" class="col-auto">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'storekeeper-repairs-items-create' })"
        >
          <i class="bi bi-file-earmark-plus fs-4"></i>
          Ajouter d'autres articles
        </button>
      </div>
    </div>
    <hr />
    <div class="row justify-content-between">
      <div class="col-xl col-12 d-flex">
        <div class="col-auto">Total Quantite :</div>
        <div class="col">
          <span
            v-if="numberItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ numberItems }}
          </span>
          Élément
        </div>
      </div>

      <div class="col-xl col-12 d-flex">
        <div class="col-auto">Quantité totale :</div>
        <div class="col">
          <span
            v-if="totalQuantityItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalQuantityItems }}
          </span>
        </div>
      </div>

      <div class="col-xl col-12 d-flex">
        <div class="col-auto">Montant Total (TTC*) :</div>
        <div class="col">
          <span
            v-if="totalPriceTTCItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalPriceTTCItems }}
          </span>
          DH
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div class="row">
      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="repair.customer_reference"
          :disabled="repair.customer_reference ? true : false"
          class="form-select"
        >
          <option value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for=""> Vehicle <span class="text-danger">*</span> </label>
        <select
          v-model="repair.vehicle_reference"
          :disabled="repair.vehicle_reference ? true : false"
          class="form-select"
        >
          <option value="--">--</option>
          <option
            v-for="(vehicle, index) in vehicles"
            :key="index++"
            :value="vehicle.reference"
          >
            {{ vehicle.registrationPlate }} {{ vehicle.manufacturerName }}
            {{ vehicle.modelName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for="">Date <span class="text-danger">*</span></label>
        <input type="date" v-model="repair.date" class="form-control" />
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for="">
          Réception a
          <span class="text-danger">*</span></label
        >
        <input
          type="datetime-local"
          v-model="repair.receptionDatetime"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for="">
          Livraison prévue a <span class="text-danger">*</span></label
        >
        <input
          type="date"
          v-model="repair.scheduledDeliveryDateTime"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for=""> Code Moteur </label>
        <select v-model="repair.engineCode" class="form-select">
          <option :value="true">Oui</option>
          <option :value="false">Non</option>
        </select>
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for=""> Code de demarrage </label>
        <input type="text" v-model="repair.startingCode" class="form-control" />
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for=""> Antivol de roues </label>
        <select v-model="repair.wheelLocks" class="form-select">
          <option :value="true">Oui</option>
          <option :value="false">Non</option>
        </select>
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for=""> Niveau de carburant </label>
        <select v-model="repair.fuelLevel" class="form-select">
          <option value="1/4">1/4</option>
          <option value="1/2">1/2</option>
          <option value="3/4">3/4</option>
          <option value="P">P</option>
        </select>
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for=""> Validite de controle technique </label>
        <input
          type="date"
          v-model="repair.technicalInspectionValidity"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for=""> KM </label>
        <input type="text" v-model="repair.mileage" class="form-control" />
      </div>

      <div class="row">
        <div class="col-3 my-3">
          <label for=""> Pneumatiques AVD </label>
          <input
            type="text"
            v-model="repair.frontRightTireCondition"
            class="form-control"
            placeholder="%"
          />
        </div>

        <div class="col-3 my-3">
          <label for=""> Pneumatiques AVG </label>
          <input
            type="text"
            v-model="repair.frontLeftTireCondition"
            class="form-control"
            placeholder="%"
          />
        </div>

        <div class="col-3 my-3">
          <label for=""> Pneumatiques ARD </label>
          <input
            type="text"
            v-model="repair.rearRightTireCondition"
            class="form-control"
            placeholder="%"
          />
        </div>

        <div class="col-3 my-3">
          <label for=""> Pneumatiques ARG </label>
          <input
            type="text"
            v-model="repair.rearLeftTireCondition"
            class="form-control"
            placeholder="%"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-4 col-6 my-3">
          <label for=""> Pare-Brise Fêlure </label>
          <select v-model="repair.windshieldCrack" class="form-select">
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>

        <div class="col-xl-3 col-md-4 col-6 my-3">
          <label for=""> Pare-Brise Cassure </label>
          <select v-model="repair.windshieldBroken" class="form-select">
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>

        <div class="col-xl-3 col-md-4 col-6 my-3">
          <label for=""> Pare-Brise Éclat </label>
          <select v-model="repair.windshieldChipped" class="form-select">
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6 col-6 my-3">
          <label for=""> Phare D </label>
          <select
            v-model="repair.rightHeadlightFunctionality"
            class="form-select"
          >
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>

        <div class="col-xl-3 col-md-6 col-6 my-3">
          <label for=""> Phare G </label>
          <select
            v-model="repair.leftHeadlightFunctionality"
            class="form-select"
          >
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>
      </div>

      <!-- Feux -->
      <div class="row">
        <div class="col-xl-3 col-md-3 col-6 my-3">
          <label for=""> Feux AVD </label>
          <select
            v-model="repair.frontRightLightFunctionality"
            class="form-select"
          >
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>

        <div class="col-xl-3 col-md-3 col-6 my-3">
          <label for=""> Fexu AVG </label>
          <select
            v-model="repair.frontLeftLightFunctionality"
            class="form-select"
          >
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>

        <div class="col-xl-3 col-md-3 col-6 my-3">
          <label for=""> Feux ARD </label>
          <select
            v-model="repair.rearRightLightFunctionality"
            class="form-select"
          >
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>

        <div class="col-xl-3 col-md-3 col-6 my-3">
          <label for=""> Feux ARG </label>
          <select
            v-model="repair.rearLeftLightFunctionality"
            class="form-select"
          >
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>
      </div>

      <div class="col-xl-12 col-12 my-3">
        <label for=""> Télécharger des photos </label>
        <input
          type="file"
          id="fileInput"
          ref="fileInput"
          name="fileInput"
          accept="image/*"
          multiple
          @change="handleImagesUpload"
          class="form-control"
        />
      </div>

      <div class="row align-items-stretch">
        <div
          v-for="(preview, index) in imagePreviews"
          :key="index"
          class="col-xl-3 col-md-3 col-6 px-xl-2 py-xl-3"
        >
          <div class="card rounded-0 h-100">
            <img :src="preview" alt="Image Preview" class="w-100" />
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-12 my-3">
        <label for=""> Remarque <span class="text-danger">*</span></label>
        <textarea
          type="date"
          v-model="repair.remark"
          class="form-control"
        ></textarea>
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-xl-8 col-12">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à la vente (Client,
            Quantité totale, Mantant totale, Nombre d'articles, ...) et
            correctes. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-xl-auto col-12">
        <button
          class="btn btn-success"
          @click="save(repair)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-xl-12 col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      repair: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        amountPaid: 0.0,
        customer_reference: this.$route.query.customer_reference,
      },
      accept: false,
      photos: [],
      imagePreviews: [],
    };
  },
  computed: {
    ...mapGetters("repair", {
      repair_items: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
      errors: "getErrors",
    }),

    ...mapGetters("customer", {
      customers: "getAll",
    }),

    ...mapGetters("vehicle", {
      vehicles: "getAll",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  methods: {
    async save(data) {
      await this.savePhotos();
      await this.$store.dispatch("repair/store", data);
      await setTimeout(((this.repair = {}), (this.accept = false)), 2000);
    },
    async refresh() {
      await this.$store.dispatch("repair/getAllItems");
      await this.$store.dispatch("employee/getAll");
      await this.$store.dispatch("customer/getAll");
      await this.$store.dispatch("vehicle/getAll");
    },
    async handleImagesUpload(event) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.photos.push(file);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },

    triggerFileInput() {
      // Programmatically trigger the hidden file input
      this.$refs.fileInput.click();
    },

    async savePhotos() {
      const formData = new FormData();
      for (let i = 0; i < this.photos.length; i++) {
        formData.append("photos[]", this.photos[i]);
      }
      this.$store.dispatch("repair/storePhotos", formData);
    },
  },
  async beforeMount() {
    await this.$store.dispatch("repair/getAllItems");
    await this.$store.dispatch("customer/getAll");
    await this.$store.dispatch("vehicle/getAll");
    this.$store.dispatch("employee/getAll");

    // (await !this.numberItems)
    //   ? (await this.$store.dispatch("notification/store", {
    //       status: "error",
    //       message: "Aucun élément n'est ajouté. Veuillez en ajouter.",
    //     }),
    //     await this.$router.push({ name: "repairs-items-create" }))
    //   : "";
  },
};
</script>
